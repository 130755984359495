(function () {
    angular.module('informaApp')
        .component('trendsPhasesCell', {
            controller: TrendsPhasesCellController,
            templateUrl: 'components/trends-chart-table/trends-phases-cell/template.html',
            bindings: {
                data: '<'
            },
        });

    function TrendsPhasesCellController() {

    }
})();
